import React, { useEffect, useRef, useState, Fragment } from 'react'
import {
  RepositoryContainer,
  LeftMenuContainer,
  ArticlesContainer,
  CategoriesResponsiveContainer
} from "./styles";
import Card from "./Card";
import { pageCategories } from "../variables/variables";
import { Link } from 'gatsby'
import { useLocation } from "@reach/router"
import arrow_down from '../../styles/icons/drop-down-arrow.svg'

const CategoriesResponsive = () => {
  const listRef = useRef(null);
  const HandleList = () => {
    if (listRef.current.classList.contains('display-menu')) {
      listRef.current.classList.remove("display-menu");
    } else {
      listRef.current.classList.add("display-menu");
    }
  }

  return (
    <CategoriesResponsiveContainer>
      <div className="subcontainer mt-4">
        <button onClick={HandleList}>
          CATEGORÍAS
          <img src={arrow_down} alt="upc arrow down" />
        </button>
        <ul ref={listRef}>
          {pageCategories.map(({ url, text }, index) => (
            <Fragment key={index}>
              <li>
                <Link to={`/${url}`}>
                  {text}
                </Link>
              </li>
              {index + 1 !== pageCategories.length &&
                <li className="line" />
              }
            </Fragment>
          ))}
        </ul>
      </div>
    </CategoriesResponsiveContainer>
  )
}

const Entries = ({ Nodes }) => {
  const [EntryCards, setEntryCards] = useState([])
  const { search } = useLocation();

  const SearchFunction = (text) => {
    const FilteredDataTitleSubtitle = Nodes.filter(data => data.titulo.toLocaleLowerCase().includes(text) || data.descripcion.toLocaleLowerCase().includes(text));
    let filteredListData = [];
    for (let i = 0; i < Nodes.length; i++) {
      let datatemp
      for (let y = 0; y < Nodes[i].lista.length; y++) {
        if (Nodes[i].lista[y].toLocaleLowerCase().includes(text)) {
          if (datatemp !== Nodes[i].id) {
            datatemp = Nodes[i].id;
            filteredListData.push(Nodes[i])
          }
        }
      }
      for (let y = 0; y < Nodes[i].contenido.json.content.length; y++) {
        const s = Nodes[i].contenido.json.content[y].content[0]
        if(s && s.value && s.value.toLocaleLowerCase().includes(text)) {
          if (datatemp !== Nodes[i].id) {
            datatemp = Nodes[i].id;
            filteredListData.push(Nodes[i])
          }
        }
      }
    }
    const filteredEntireData = FilteredDataTitleSubtitle.concat(filteredListData);
    const ArrayPrototype = Array.prototype 
    ArrayPrototype.unique = function (a) {
      return function () { return this.filter(a) }
    }(function (a, b, c) {
      return c.indexOf(a, b + 1) < 0
    });
    setEntryCards(filteredEntireData.unique())
  }

  useEffect(() => {
    if (search && search.split("?search=")[1]) {
      SearchFunction(decodeURI(search.split("?search=")[1]).toLocaleLowerCase())
    }
  }, [search])
  return (
    <section class="upcc-cards two" style={{marginTop: 80 + 'px'}}>
      {!search ?
        <>
          {Nodes.map(({ descripcion, enExhibicion, fecha, id, imagenDeLaEntrada, titulo, tituloDeRespaldo }) => (
            <Card
              key={id}
              active={enExhibicion}
              title={titulo}
              titler={tituloDeRespaldo}
              subtitle={descripcion}
              image={imagenDeLaEntrada}
              date={fecha}
            />
          ))}
        </> :
        <>
          {EntryCards.map(({ descripcion, enExhibicion, fecha, id, imagenDeLaEntrada, titulo, tituloDeRespaldo }) => (
            <Card
              key={id}
              active={enExhibicion}
              title={titulo}
              titler={tituloDeRespaldo}
              subtitle={descripcion}
              image={imagenDeLaEntrada}
              date={fecha}
            />
          ))}
        </>
      }
    </section>
  )
}

const Arteycultura = ({
  nodesBlog,
  nodesInfo
}) => {
  const { blogSubtitulo } = nodesInfo[0];
  return (
    <Entries Nodes={nodesBlog} />
  )
}

export default Arteycultura
