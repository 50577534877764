import React from 'react'
import { CardContainer } from "./styles";
import Image from 'gatsby-image';
import { Link } from 'gatsby'
import { normalize } from "../../hooks/normalize";
import arrow    from "../../images/upcc/arrow.svg"

const Card = ({
  title,
  titler,
  subtitle,
  image,
  date
}) => {
  return (
    <article class="upcc-card">
      <Link to={`/arteycultura/${normalize(title)}`}>
        <img class="upcc-card-img" src={ image.file.url } />
      </Link>
      <span class="upcc-tag upcc-card-tag">NOTICIAS</span>
      <Link to={`/arteycultura/${normalize(title)}`}>
        <h5 class="upcc-h5 upcc-card-title">{ title } { subtitle }</h5>
      </Link>
      {/*<p class="upcc-small upcc-card-desc">{ description }</p>*/}
      <footer>
        <span class="upcc-small upcc-card-date">{ date }</span>
        <Link class="upcc-card-more" to={`/arteycultura/${normalize(title)}`}>
          <p class="upcc-small underline">Leer más</p>
          <img src={arrow} />
        </Link>
      </footer>
    </article>
  )
}

export default Card
